
const Footer = () => {

    return (
        <footer>
            <div>Challenge by <a href="https://www.frontendmentor.io?ref=challenge" target="_blank" rel="noreferrer">Frontend Mentor</a></div>
            <div>Coded by <a href="https://gitdrinou.github.io/" target="_blank" rel="noreferrer">Sandrine@GitDrinou</a></div>
        </footer>
    )    
}

export default Footer